<template>
  <!-- div background form -->
  <div class="validate-page d-flex justify-center">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="validate-form"
    >
      <v-card class="validate-card">
        <v-card-title class="validate-card-title justify-center">
          <span>Verify Account</span>
        </v-card-title>
        <!-- Content Card Form -->
        <v-card-text class="px-0">
          <v-text-field
            v-model="dataForm.code"
            :rules="codeRules"
            label="Code"
            prepend-icon="mdi-qrcode"
          />
        </v-card-text>
        <!-- v-card-actions -->
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                x-large
                rounded
                :disabled="!valid"
                color="success"
                width="100%"
                @click="validate"
              >
                Submit
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              align="center"
            >
              <v-btn
                text
                @click="$router.push('resend-email')"
              >
                Resend
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <!-- end v-card-actions -->
      </v-card>
    </v-form>
  </div>
</template>

<script>
  import Repository from '../repository/RepositoryFactory'
  const RegisterRepository = Repository.get('register')
  export default {
    name: 'VerifyRegisterForm',
    data: () => ({
      apiKey: '',
      dataForm: {
        code: '',
      },
      valid: true,
      codeRules: [
        (v) => !!v || 'Code is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
    }),
    methods: {
      async validate () {
        const valueCheck = await this.$refs.form.validate()
        // if valueCheck = false then stop function register
        if (!valueCheck) {
          return
        }
        // if valueCheck = true
        const userId = this.$route.query.userId || ''
        let tokenActive = this.$route.query.tokenActive || ''
        tokenActive = tokenActive.replace(/ /g, '+')
        // Data validate ok
        const dataBody = {
          code: this.dataForm.code,
          tokenActive,
        }
        // call API verify
        this.showProgressCircular()
        const verifyRegisterRepository = await RegisterRepository.verifyRegister(dataBody, userId)
        this.hideProgressCircular()
        // verifyRegister = API return data
        const verifyRegister = verifyRegisterRepository.data
        // if verifyRegister.status = false then error verifyRegister.data.message and stop function validate
        if (verifyRegister.status === false) {
          this.$toast.error(verifyRegister.data.message)
          return
        }
        // if verifyRegister.status = true then notification success
        // then push page LoginForm
        this.$toast.success('Kích hoạt tài khoản thành công')
        window.location.href = 'login'
        // this.$router.go('login')
      },
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Verify Register',
    },
  }
</script>

<style lang="scss" scoped>
  // full background desktop 100vh
  $fullScreen: 100vh;
  $backgroundImage: url("https://colorlib.com/etc/lf/Login_v4/images/bg-01.jpg");
  .validate-page {
    background-image: $backgroundImage;
    background-size: cover;
    padding: 15px;
    min-height: $fullScreen;
    .validate-form {
      width: 500px;
      .validate-card {
        padding: 55px;
        &-title {
          font-size: 39px;
          font-weight: bold;
        }
      }
    }
  }
  // media mobie
  @media only screen and (max-width: 600px) {
    .validate-card {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
</style>
